var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"ConfirmModal",attrs:{"destroy-on-close":true,"close-on-click-modal":false,"title":_vm.verificationStatus == 0 ? '核定收货' : '核定详情',"visible":_vm.show,"width":"1300px"},on:{"update:visible":function($event){_vm.show=$event}}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"row-class-name":_vm.tableRowClassName,"data":_vm.productDetailData,"max-height":"300"}},[_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"brandName","label":"商品品牌"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"goodsName","label":"商品名称"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","width":"100","prop":"barNo","label":"商品条形码"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"purchaseUnit","label":"采购单位"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"purchaseSpecification","label":"规格"}}),_c('el-table-column',{attrs:{"width":"100","prop":"inPrice","label":"采购单价(元)"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","prop":"goodsNum","label":"采购数量"}}),_c('el-table-column',{attrs:{"width":"110","prop":"middlemanDeliverNum","label":"发货数量"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","width":"110","prop":"storeConfirmNum","label":"门店收货数量"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.storeConfirmNum || '-')+" ")]}}])}),_c('el-table-column',{attrs:{"width":_vm.verificationStatus == 0 ? 155 : 110,"label":"总店核定数量"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.verificationStatus == 0)?_c('el-input-number',{attrs:{"min":1,"max":row.middlemanDeliverNum,"size":"mini"},on:{"blur":function () {
              !row.middlemanConfirmNum ? (row.middlemanConfirmNum = 1) : ''
            }},model:{value:(row.middlemanConfirmNum),callback:function ($$v) {_vm.$set(row, "middlemanConfirmNum", $$v)},expression:"row.middlemanConfirmNum"}}):_c('span',[_vm._v(_vm._s(row.middlemanConfirmNum))])]}}])}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","width":"100","label":"采购差价(元)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('span',[_vm._v(_vm._s(Number( (row.inPrice || 0) * (Number(row.goodsNum) - Number(row.middlemanConfirmNum) || 0) ).toFixed(2)))])]}}])}),(_vm.verificationStatus == 0 && _vm.currentStatus == '3')?_c('el-table-column',{attrs:{"width":"100","label":"保质期(天)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('el-input',{attrs:{"placeholder":"","size":"mini"},on:{"input":function($event){row.guaranteePeriod = row.guaranteePeriod.replace(/[^0-9]/g, '')}},model:{value:(row.guaranteePeriod),callback:function ($$v) {_vm.$set(row, "guaranteePeriod", $$v)},expression:"row.guaranteePeriod"}})]}}],null,false,3744224724)}):_vm._e()],1),(_vm.tips)?_c('p',{staticClass:"confirmTips"},[_vm._v(_vm._s(_vm.tips))]):_vm._e(),_c('el-form',{ref:"form",staticStyle:{"margin-top":"20px"},attrs:{"model":_vm.form,"rules":_vm.rules2,"label-width":"100px","inline":false,"size":"small"}},[(_vm.verificationStatus == 0)?_c('el-form-item',{attrs:{"label":"上传凭证","prop":_vm.isNecessary == 0 ? 'approvalPicture' : 'null'}},[_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"action":'/purchase/fileUpload/upload?token=' + _vm.token,"on-preview":_vm.handlePictureCardPreview,"before-upload":_vm.beforeUpload,"file-list":_vm.imgsList,"multiple":true,"on-success":_vm.successHandler,"on-remove":_vm.removeHandler,"limit":5,"list-type":"picture-card"},scopedSlots:_vm._u([{key:"tip",fn:function(){return [_c('div',{staticClass:"el-upload__tip"},[_vm._v("最多可上传5张")])]},proxy:true}],null,false,2135122874)},[_c('i',{staticClass:"el-icon-plus"})])],1):_vm._e(),(_vm.imagesList.length && _vm.verificationStatus != 0)?_c('el-form-item',{attrs:{"label":"图片凭证："}},_vm._l((_vm.imagesList),function(item,index){return _c('el-image',{key:index,staticStyle:{"width":"100px","height":"100px","margin-right":"10px","cursor":"zoom-in"},attrs:{"src":item.src},on:{"click":function () {
            _vm.dialogImageUrl = item.src
            _vm.imgDialogVisible = true
          }}})}),1):_vm._e()],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.show = false}}},[_vm._v("取消")]),(_vm.verificationStatus == 0)?_c('el-button',{attrs:{"disabled":!_vm.productDetailData.length,"loading":_vm.btnLoading,"type":"primary"},on:{"click":_vm.confirmSendProduct}},[_vm._v(" 核定收货 ")]):_vm._e(),_c('el-button',{attrs:{"loading":_vm.btnLoading,"type":"primary"},on:{"click":_vm.downloadOrder}},[_vm._v(" 下载收货单 ")])],1),_c('el-dialog',{staticClass:"bigImgWrap",attrs:{"append-to-body":"","visible":_vm.imgDialogVisible},on:{"update:visible":function($event){_vm.imgDialogVisible=$event}}},[_c('img',{attrs:{"width":"100%","src":_vm.dialogImageUrl,"alt":""}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }