<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="详情"
    :visible.sync="show"
    width="1200px"
  >
    <div class="itemWrap">
      <h4>订单信息</h4>
      <el-form inline size="small">
        <el-form-item label="订单编号：">
          <span>{{ currentItem.purchaseNo || '-' }}</span>
        </el-form-item>
        <el-form-item label="下单时间：">
          <span>{{ currentItem.createTimeStr || '-' }}</span>
        </el-form-item>
        <el-form-item label="关联售后订单：">
          <span>{{ targetItem.afterSaleOrderId || '-' }}</span>
        </el-form-item>
      </el-form>
    </div>

    <div class="itemWrap">
      <h4>来源信息</h4>
      <el-form inline size="small">
        <el-form-item label="商户名称：">
          <span>{{ currentItem.storesName || '-' }}</span>
        </el-form-item>
        <el-form-item label="收货人：">
          <span>{{ currentItem.cargoReceiver || '-' }}</span>
        </el-form-item>
        <el-form-item label="联系电话：">
          <span>{{ currentItem.phone || '-' }}</span>
        </el-form-item>
        <el-form-item label="收货地址：">
          <span>{{ currentItem.storeAddress || '-' }}</span>
        </el-form-item>
      </el-form>
    </div>

    <div class="itemWrap">
      <h4>发货信息</h4>
      <el-form label-position="left" size="small">
        <el-form-item label="发货状态：">
          <span>{{ currentItem.arrivalTime ? '已发货' : '未发货' }}</span>
        </el-form-item>

        <template v-if="currentItem.arrivalTime">
          <el-form-item label="发货时间：">
            <span>{{ currentItem.arrivalTimeStr }}</span>
          </el-form-item>
          <el-form-item label="配送方式：">
            <span>{{
              +targetItem.purchaseLogistics.type == 3
                ? '快递配送'
                : +targetItem.purchaseLogistics.type == 2
                ? '零单物流'
                : +targetItem.purchaseLogistics.type == 1
                ? '整车物流'
                : '-'
            }}</span>
          </el-form-item>

          <template v-if="+targetItem.purchaseLogistics.type === 1">
            <el-form-item label="车牌号：">
              <span>{{ targetItem.purchaseLogistics.carNo || '-' }}</span>
            </el-form-item>

            <el-form-item label="联系电话：">
              <span>{{ targetItem.purchaseLogistics.phone || '-' }}</span>
            </el-form-item>
          </template>

          <template v-if="+targetItem.purchaseLogistics.type === 2">
            <el-form-item label="快递公司：">
              <span>{{
                targetItem.purchaseLogistics.logisticsCompany || '-'
              }}</span>
            </el-form-item>
            <el-form-item label="联系电话：">
              <span>{{ targetItem.purchaseLogistics.phone || '-' }}</span>
            </el-form-item>
          </template>

          <template v-if="targetItem.purchaseLogistics.type === 3">
            <el-form-item label="快递公司：">
              <span>{{
                targetItem.purchaseLogistics.logisticsCompany || '-'
              }}</span>
            </el-form-item>

            <el-form-item label="快递编号：">
              <span>{{ targetItem.purchaseLogistics.logisticsNo || '-' }}</span>
            </el-form-item>
          </template>
        </template>
      </el-form>
    </div>

    <div class="itemWrap">
      <h4>收款信息</h4>
      <el-form inline size="small">
        <el-form-item label="收款状态：">
          <span>{{ currentItem.payStatus == 1 ? '已付款' : '未付款' }}</span>
        </el-form-item>
        <el-form-item label="收款时间：">
          <span>{{ targetItem.collectionDate || '-' }}</span>
        </el-form-item>
        <el-form-item label="门店付款金额：">
          <span>{{ currentItem.payPrice || '-' }}</span>
        </el-form-item>
        <el-form-item label="支付方式：">
          <span>{{
            currentItem.payType == 0
              ? '扫码支付'
              : currentItem.payType == 1
              ? '工行卡支付'
              : currentItem.payType == 2
              ? '线下支付'
              : currentItem.payType == 3
              ? '余额/赊销'
              : '-'
          }}</span>
        </el-form-item>
        <el-form-item label="付款时间：">
          <span>{{ currentItem.payTimeStr || '-' }}</span>
        </el-form-item>
      </el-form>
    </div>

    <div class="itemWrap">
      <h4>采购商品明细</h4>
      <el-table
        border
        :max-height="300"
        :data="purchaseDetailData"
        size="small"
        v-loading="loading"
        style="width: 100%"
      >
        <el-table-column prop="goodsName" label="商品名称"></el-table-column>
        <el-table-column prop="barNo" label="条形码"></el-table-column>
        <el-table-column prop="purchaseUnit" label="单位"></el-table-column>
        <el-table-column prop="inPrice" label="采购单价(元)"></el-table-column>
        <el-table-column prop="goodsNum" label="采购数量"></el-table-column>
        <el-table-column prop="payPrice" label="金额小计(元)"></el-table-column>
      </el-table>
      <p class="totalWrap">合计: {{ totalPayPrice }} 元</p>
    </div>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  data() {
    return {
      targetItem: {
        purchaseLogistics: {}
      },
      purchaseDetailData: [],
      loading: false,
      btnLoading: false,
      totalCount: 0,
      params: {
        limit: 20,
        page: 1
      }
    }
  },
  created() {
    this.getDetail()
  },
  computed: {
    totalPayPrice() {
      return this.purchaseDetailData.reduce((num, item) => {
        num += Number(item.payPrice)
        return num
      }, 0)
    }
  },
  methods: {
    async getDetail() {
      try {
        this.loading = true

        const res = await this.$api.storeProcurementOrders.getDetailList({
          purchaseId: this.currentItem.id
        })

        if (res.detail) {
          this.purchaseDetailData = res.detail.middlePurchaseDetailVoList

          this.targetItem = {
            afterSaleOrderId: res.detail.afterSaleOrderId,
            purchaseLogistics: res.detail.purchaseLogistics || {},
            collectionStatus: res.detail.collectionStatus,
            collectionDate: res.detail.collectionDate
          }
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 5px !important;
  margin-right: 40px !important;
}

::v-deep .el-form-item__label {
  padding: 0 !important;
}

.itemWrap {
  margin-bottom: 12px;
  h4 {
    margin-bottom: 5px;
  }
}

.totalWrap {
  text-align: right;
  font-size: 14px;
  flex-shrink: 0;
  font-weight: bold;
  margin-top: 8px;
}
</style>
