<template>
  <el-dialog
    class="ConfirmModal"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :title="verificationStatus == 0 ? '核定收货' : '核定详情'"
    :visible.sync="show"
    width="1300px"
  >
    <el-table
      :row-class-name="tableRowClassName"
      v-loading="loading"
      :data="productDetailData"
      max-height="300"
      style="width: 100%"
    >
      <el-table-column show-overflow-tooltip prop="brandName" label="商品品牌">
      </el-table-column>
      <el-table-column show-overflow-tooltip prop="goodsName" label="商品名称">
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        width="100"
        prop="barNo"
        label="商品条形码"
      >
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="purchaseUnit"
        label="采购单位"
      >
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="purchaseSpecification"
        label="规格"
      >
      </el-table-column>
      <el-table-column
        width="100"
        prop="inPrice"
        label="采购单价(元)"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="goodsNum"
        label="采购数量"
      ></el-table-column>
      <el-table-column
        width="110"
        prop="middlemanDeliverNum"
        label="发货数量"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        width="110"
        prop="storeConfirmNum"
        label="门店收货数量"
      >
        <template slot-scope="{ row }">
          {{ row.storeConfirmNum || '-' }}
        </template>
      </el-table-column>

      <el-table-column
        :width="verificationStatus == 0 ? 155 : 110"
        label="总店核定数量"
      >
        <template slot-scope="{ row }">
          <el-input-number
            @blur="
              () => {
                !row.middlemanConfirmNum ? (row.middlemanConfirmNum = 1) : ''
              }
            "
            v-if="verificationStatus == 0"
            :min="1"
            :max="row.middlemanDeliverNum"
            size="mini"
            v-model="row.middlemanConfirmNum"
          >
          </el-input-number>
          <span v-else>{{ row.middlemanConfirmNum }}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip width="100" label="采购差价(元)">
        <template slot-scope="{ row }">
          <span>{{
            Number(
              (row.inPrice || 0) *
                (Number(row.goodsNum) - Number(row.middlemanConfirmNum) || 0)
            ).toFixed(2)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        width="100"
        v-if="verificationStatus == 0 && currentStatus == '3'"
        label="保质期(天)"
      >
        <template slot-scope="{ row }">
          <el-input
            v-model="row.guaranteePeriod"
            placeholder=""
            size="mini"
            @input="
              row.guaranteePeriod = row.guaranteePeriod.replace(/[^0-9]/g, '')
            "
          ></el-input>
        </template>
      </el-table-column>
    </el-table>
    <p v-if="tips" class="confirmTips">{{ tips }}</p>
    <el-form
      :model="form"
      style="margin-top: 20px"
      ref="form"
      :rules="rules2"
      label-width="100px"
      :inline="false"
      size="small"
    >
      <el-form-item
        v-if="verificationStatus == 0"
        label="上传凭证"
        :prop="isNecessary == 0 ? 'approvalPicture' : 'null'"
      >
        <el-upload
          ref="upload"
          class="upload-demo"
          :action="'/purchase/fileUpload/upload?token=' + token"
          :on-preview="handlePictureCardPreview"
          :before-upload="beforeUpload"
          :file-list="imgsList"
          :multiple="true"
          :on-success="successHandler"
          :on-remove="removeHandler"
          :limit="5"
          list-type="picture-card"
        >
          <i class="el-icon-plus"></i>
          <template #tip>
            <div class="el-upload__tip">最多可上传5张</div>
          </template>
        </el-upload>
      </el-form-item>

      <el-form-item
        v-if="imagesList.length && verificationStatus != 0"
        label="图片凭证："
      >
        <el-image
          @click="
            () => {
              dialogImageUrl = item.src
              imgDialogVisible = true
            }
          "
          v-for="(item, index) in imagesList"
          :key="index"
          style="
            width: 100px;
            height: 100px;
            margin-right: 10px;
            cursor: zoom-in;
          "
          :src="item.src"
        >
        </el-image>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>

      <el-button
        v-if="verificationStatus == 0"
        :disabled="!productDetailData.length"
        :loading="btnLoading"
        type="primary"
        @click="confirmSendProduct"
      >
        核定收货
      </el-button>
      <el-button :loading="btnLoading" type="primary" @click="downloadOrder">
        下载收货单
      </el-button>
    </span>

    <el-dialog
      class="bigImgWrap"
      append-to-body
      :visible.sync="imgDialogVisible"
    >
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import { BASE_URL } from '@/constant'
export default {
  mixins: [dialogCommonParams],
  data() {
    return {
      loading: false,
      btnLoading: false,
      currentNo: '',
      currentStatus: '',
      tips: '',
      verificationStatus: '0',
      isNecessary: 1,
      imgsList: [],
      dialogImageUrl: '',
      imgDialogVisible: false,
      productDetailData: [],
      imagesList: [],
      form: {
        approvalPicture: []
      },
      rules2: {
        approvalPicture: [
          {
            required: true,
            trigger: 'blur',
            message: '请上传凭证'
          }
        ]
      }
    }
  },
  computed: {
    token() {
      return localStorage.getItem('token')
    }
  },
  created() {
    this.verificationStatus = this.currentItem.verificationStatus
    this.currentStatus = this.currentItem.status
    this.queryAcceptGoods()
  },
  methods: {
    downloadOrder() {
      window.open(
        BASE_URL +
          '/purchase/middle-purchase/verify/receipt/info/down?middleManPurchaseNo=' +
          this.currentItem.purchaseNo +
          '&token=' +
          localStorage.getItem('token'),
        'target'
      )
    },
    confirmSendProduct() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$confirm(
            '请再次确认采购商品均已收到，一旦操作将不可进行逆转！',
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }
          ).then(async () => {
            if (this.form.approvalPicture.length) {
              this.btnLoading = true

              try {
                await this.$api.storeProcurementOrders.uploadPurchasePicture({
                  purchaseNo: this.currentItem.purchaseNo,
                  approvalPicture: this.form.approvalPicture
                })

                this.confirmAgain()
              } catch (e) {
                console.log()
              } finally {
                this.btnLoading = false
              }
            } else {
              this.confirmAgain()
            }
          })
        }
      })
    },
    async confirmAgain() {
      try {
        await this.$api.storeProcurementOrders.checkAcceptGoods({
          purchaseNo: this.currentItem.purchaseNo,
          storeId: this.currentItem.storeId,
          middlemanId: this.currentItem.purchaseNo,
          goodsNumReqList: this.productDetailData
        })
        this.$message.success('核定收货成功！')
        this.show = false
        this.$emit('getData')
      } catch (e) {
        console.log(e)
      } finally {
        this.btnLoading = false
      }
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.goodsNumHavedChange) {
        return 'red-row'
      }
      return ''
    },
    beforeUpload(file) {
      const isJPG = /image\/.+/.test(file.type)
      const isLt2M = file.size / 1024 / 1024 < 1

      if (!isJPG) {
        this.$message.error('只能上传图片!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 1MB!')
      }
      return isJPG && isLt2M
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.imgDialogVisible = true
    },
    successHandler(file, fileList) {
      this.form.approvalPicture.push({
        picturePath: file.url
      })
    },
    removeHandler(file, fileList) {
      this.form.approvalPicture = fileList.map(item => ({
        picturePath: item.response.url
      }))
    },
    async queryAcceptGoods() {
      try {
        this.loading = true
        const r = await this.$api.storeProcurementOrders.queryAcceptGoods({
          middleManPurchaseNo: this.currentItem.purchaseNo
        })
        this.tips = r.desc || ''

        this.productDetailData = (r.info || []).map(item => {
          item.middlemanConfirmNum =
            item.middlemanConfirmNum ||
            item.storeConfirmNum ||
            item.middlemanDeliverNum ||
            1
          item.goodsNumHavedChange =
            item.middlemanDeliverNum !== item.storeConfirmNum

          const {
            brandName,
            goodsName,
            barNo,
            purchaseUnit,
            purchaseSpecification
          } = item.middlemanGoods

          const { inPrice, goodsNum } = item.middlePurchaseDetailVo

          item.brandName = brandName
          item.goodsName = goodsName
          item.barNo = barNo
          item.purchaseUnit = purchaseUnit
          item.purchaseSpecification = purchaseSpecification
          item.inPrice = inPrice
          item.goodsNum = goodsNum

          item.guaranteePeriod = item.middlemanGoods.timeLimit

          return item
        })
        this.isNecessary = r.isNecessary

        if (r.pictures) {
          this.imagesList = r.pictures.map(item => {
            return {
              src: this.$utils.getImg(item.picturePath)
            }
          })
        } else {
          this.imagesList = []
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.ConfirmModal {
  .confirmTips {
    margin-top: 10px;
    color: #666;
    background: #feffc5;
    padding: 2px 10px;
    font-size: 14px;
  }
  ::v-deep .el-upload--picture-card,
  ::v-deep .el-upload-list--picture-card .el-upload-list__item {
    width: 100px !important;
    height: 100px !important;
    line-height: 98px !important;
  }
}
</style>
